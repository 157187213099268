.css-o1utbh, .css-o1utbh p {
    font-family: revert!important;
}

.css-o1utbh {
    height: 100%;
}

.css-692fgp, .css-vcfpfv, .css-1b3lipl:before {
    background-color: rgba(0,0,0,0.5)!important;
}

/* .css-1b3lipl {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
    justify-content: space-between;
} */

.resume-cols {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.resume-cols h3 {
    margin-top: 0;
}

#ecs .css-o1utbh{
    height: auto;
}

.timelines {
    display: flex;
}

.sublist {
    margin-left: 40px!important;
} 

@media only screen and (max-width: 650px) {
    .timelines {
        flex-direction: column;
    }
}
html, body {
  height: 100%;
}

#page-content {
  min-height: 100%;
  position: relative;
}

body {
  font-family: 'Courier New', Courier, monospace;
  margin: 0;
  background: linear-gradient(90deg, rgba(2, 0, 36, 0.5) 0%, rgba(9, 9, 121, 0.65) 35%, rgba(9, 124, 147, 0.8) 100%);
  color: white !important;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-attachment: fixed;
}

body::-webkit-scrollbar {
  display: none;
}

a {
  color: #fff;
}

a:visited {
  color: #fff;
}

a:hover {
  color: rgba(0, 0, 0, 0.5);
  transition: 0.2s;
}

.content {
  padding: 10px 50px 100px 50px;
  min-height: calc(100vh - 150px);
}

.content h1 {
  text-align: center;
}


.align-right {
  text-align: right;
}

table h3,
table p {
  margin: 0;
}

.scrolling-text {
  overflow: hidden;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: .15em;
  animation:
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

@media only screen and (min-device-width: 650px) {
  h1, h1 a {
    font-size: 3.6rem;
  }
  
  h2, h2 a {
    font-size: 2.4rem;
  }
  
  h3, h3 a {
    font-size: 1.8rem;
  }
  
  body {
    font-size: 1.2em;
  }
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent
  }
}
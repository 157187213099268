.navbar {
    padding: 10px 50px;
    background: transparent;
}

.navbar .links {
    display: flex;
    justify-content: flex-end;
}

.navbar .links a {
    text-decoration: none;
    margin-left: 5%;
    transition: 0.2s;
}

.navbar-btn {
    display: none;
}

@media only screen and (max-device-width: 650px) {
    .navbar .links {
        display: none;
        flex-direction: column;
        height: calc(100vh - 65px);
        align-items: center;
        justify-content: space-evenly;
    }

    .navbar .links.open {
        display: flex;
    }

    .navbar-btn {
        display: block;
        color: white;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
        display: flex;
        justify-content: flex-end;
        background: none;
        border: none;
    }
}
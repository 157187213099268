.project {
    display: flex;
}

.project h2,
.project h3 {
    margin-bottom: 5px;
    margin-top: 5px;
    text-wrap: balance;
}

.project p {
    margin-top: 5px;
    margin-bottom: 50px;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.carousel .slide img {
    height: auto;
    margin-bottom: 20px;
    width: -webkit-fill-available;
}

.project-pad-left {
    margin-left: 70px;
}

.project-pad-right {
    margin-right: 70px;
}

.project-image, #coveytown-image, #csgo-image {
    width: 50%;
}

.project-image #sportsbetting-image {
    width: 75%;
}

#college-image {
    width: 70%;
}

.project-caption {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.carousel-root {
    background: rgba(0, 0, 0, 0.5);
}

.carousel .slider {
    align-items: center;
    margin: revert;
}

#projects-swipe-button {
    font-family: 'Courier New', Courier, monospace;
    margin: 10px 0;
    padding: 5px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    outline: none;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: 0.5s;
}

#projects-swipe-button:hover {
    background: rgba(0, 0, 0, 0.8);
    transition: 0.5s;
}

#knotaproblem-image {
    width: 90%;
}

#knotaproblem-prototype {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 100%;
}

@media only screen and (max-device-width: 650px) {
    #projects-swipe-button {
        display: none;
    }

    .carousel-slider {
        padding-bottom: 50px;
    }

    .project {
        flex-direction: column;
        align-items: center;
    }

    .slider.animated {
        align-items: flex-start;
    }
}